<template>
    <!-- Vue Slot with Glassomorphism background -->
    <div class="glass-effect">
      <slot></slot>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
// Glassomorphism background on glass-columnsContainer
.glass-effect{
border-radius: 0.3125em;
opacity: 1;
backdrop-filter: blur(1.875em);
-webkit-backdrop-filter: blur(1.875em);
}
</style>
